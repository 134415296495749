import { baseGlobals } from '@root/typescript/baseGlobals'

import ajax from '@common/ajax/v2/ajax'

import type { ILoginResponse  } from '../../definitions'

import styles from './Form.css'

interface IProps {
  readonly action: string
  readonly success: (jwtToken: string) => void
  readonly error: (statusCode: number) => void
  readonly children?: HTMLElement[]
}

/**
 * Közösített form komponens, ami ajax-ot küld a hagyományos form submit helyett.
 * @param props - JSX props {@link IProps}.
 */
export default function Form (props: IProps): HTMLFormElement {
  const form: HTMLFormElement = (
    <form className={ styles.form }>{ props.children }</form>
  )

  form.addEventListener('submit', async (e: Event) => {
    e.preventDefault()

    // Legyen hülyebiztos az url hozzáadás.
    const url = `${ baseGlobals.isDev ? 'http://localhost:3100' : '' }${ props.action.startsWith('/') ? props.action : `/${ props.action }` }`

    const { isSuccess, result, xhr } = await ajax(
      url,
      {
        // A form input elemei alapján majd összeszedi az adatot.
        data: form
      }
    )

    if (!isSuccess) {
      props.error(xhr.status)

      return
    }

    props.success((result as ILoginResponse).jwtToken)
  })

  return form
}
