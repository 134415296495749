import './WorkingScreen.scss'

/**
 * Töltőképernyő mutatása.
 * @param title - A fő szöveg.
 * @param text  - Az alszöveg.
 */
export default function WorkingScreen (
  title = 'Feltöltés folyamatban.',
  text = 'A folyamat végén az oldal frissülni fog.'
): void {
  const black = (
    <div className="working-screen__black">
      <h2 className="working-screen__title">{ title }</h2>
      <h3>{ text }</h3>

      <div className="working-screen__circle">
        <div className="working-screen__boring" data-type="1" />
      </div>
    </div>
  )

  document.body.appendChild(black)
}
